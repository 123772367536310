import React from "react";
import { graphql } from "gatsby";
import {Helmet} from "react-helmet";

import DefaultLayout from "../components/layouts/default";
import FranchiseIcon from "../components/franchiseicon/FranchiseIcon";
import Header from "../components/layouts/index/Header";
import SeasonSchema from '../components/schemaorg/SeasonSchema';
import TaglineList from '../components/taglinelist/TaglineList';

import transformTaglines from "../utils/transform-tagline";

export default ({ data, pageContext }) => {
    let taglines = transformTaglines(data.realTaglines.taglines)
                        .slice().reverse();

    let season = taglines[0].node.seasonList.edges[0].node;
    let franchise = season.franchise;

    let experiences = {};
    let currentExperience = "season_" + pageContext.number;
    data.realTaglines.franchises.edges[0].node.seasonSet.edges.forEach(
        ({ node }) => { 
            experiences["season_" + node.number] = { dest: '/' + ['season', franchise.slug, node.number].join('/').toLowerCase(), text: node.number }; 
        }
    );

    return (<DefaultLayout>
        <Helmet>
            <title>{ `${franchise.city} Housewives Season ${season.number} Complete Tagline Ranking!` }</title>
        </Helmet>
        <Header 
            blurb={ `Every tagline from <strong>Season ${season.number}</strong> of the Real Housewives of ${franchise.city}` } 
            experiences={ experiences }
            experienceLabel="All Seasons:"
            experience={ currentExperience }
            headerIcon={ <FranchiseIcon franchise={ franchise } /> }
        />
        <SeasonSchema season={ season } franchise={ franchise } />
        <TaglineList taglines={ taglines } />
    </DefaultLayout>);
}

export const query = graphql`
    query($number: Int, $franchise: String) {
        realTaglines {
            franchises(slug: $franchise) {
                edges {
                    node {
                        seasonSet {
                            edges {
                                node {
                                    number
                                }
                            }
                        }
                    }
                }
            }
            taglines(season_Number: $number, season_Franchise_Slug: $franchise) {
                edges {
                    node {
                        id
                        tagline
                        rank
                        slug
                        housewife {
                            fullName
                            slug
                            housewifeimageSet {
                                crops {
                                    height
                                    name
                                    url
                                    width
                                }
                            }
                        }
                        seasonList:season {
                            edges {
                                node {
                                    number
                                    franchise {
                                        city
                                        slug
                                    }
                                }
                            }
                        }
                        taglinescore {
                            total
                        }
                    }
                }
            }
        }
    }
`
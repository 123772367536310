import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import GenericHeader from "../header/Header";

import "./Header.scss";

export default class Header extends React.PureComponent {
    blurb = "<strong>The Real Taglines of Every Housewife:</strong> A comprehensive ranking of all <strong>" + this.props.totalTaglines + "</strong> Real Housewive taglines... Enjoy!";

    experiences = {
        10: {dest: "/top-10", text: "Top 10"}, 
        100: {dest: "/", text: "Top 100"}, 
        "all": {dest: "/all", text: "All"},
        "wives": {dest: "/wives", text: "Wives"}
    };

    constructor(props) {
        super(props);

        if(props.experiences) {
            this.experiences = props.experiences;
        }

        if(props.blurb) {
            this.blurb = props.blurb;
        }

        this.state = { experience: props.experience }
    }

    render() {
        return (
            <GenericHeader blurb={ this.blurb } headerIcon={ this.props.headerIcon }>
                { this.props.experienceLabel ? <div className="index-header-experience__label">{ this.props.experienceLabel }</div> : "" }
                <div className="index-header-experience">
                    { Object.entries(this.experiences).map(( [num, {dest, text}] ) => (
                        <div key={ num } className="index-header-experience__button-holder">
                            <Link to={ dest }
                                className={"index-header-experience__button " + (this.state.experience === num ? 'selected' : '')}>
                                { text }
                            </Link>
                        </div>    
                    )) }
                </div>
            </GenericHeader>
        );
    }
}

Header.propTypes = {
    headerIcon: PropTypes.string,
    experienceLabel: PropTypes.string,
    experience: PropTypes.string,
    totalTaglines: PropTypes.number
}
import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

export default class FranchiseIcon extends React.PureComponent {
    franchiseIcon;

    constructor(props) {
        super(props);

        try {
            this.franchiseIcon = require(`../../assets/images/franchise_icons/${props.franchise.slug}.png`);
        } catch(err) {}
    }

    render() {
        if(this.franchiseIcon) {
            return (<Link to={ `/franchise/${ this.props.franchise.slug }` }>
                    <img src={ this.franchiseIcon } className={ `franchise-icon franchise-icon--${this.props.franchise.slug}` } alt="The Real Taglines of Every Housewife"/>
                </Link>);
        } 

        return '';
    }
}

FranchiseIcon.propTypes = {
    franchise: PropTypes.shape({
        city: PropTypes.string.isRequired,
        acronym: PropTypes.string,
        slug: PropTypes.string
    })
}
import React from "react";

import PropTypes from 'prop-types'

export default function SeasonSchema({ season: { number }, franchise: { city } }) {
    const scriptContent = `
        {
        "@context": "http://schema.org/",
        "@type": "TVSeries",
        "name": "The Real Housewives of ${city}",
        "containsSeason": [
            {
            "@type": "TVSeason",
            "name": "Season ${number}"
            }
        ]
        }
    `
    return (
        <script type="application/ld+json" dangerouslySetInnerHTML={{__html: scriptContent }}></script>
    );
}

SeasonSchema.propTypes = {
    season: PropTypes.shape({
        number: PropTypes.number.isRequired,
    }),
    franchise: PropTypes.shape({
        city: PropTypes.string.isRequired,
    })
}